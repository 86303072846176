import { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import addIcon from '../../../../../assets/icons/addIcon.svg';
import {
  BodyCell,
  CreateQueueButton,
  HeadCell,
  QueueInput,
  Table,
  Thead,
} from '../../styles';
import { IQueue } from '../..';
import { ModalTitle, StyleModal, Tbody, TableRow } from './styles';
import api from '../../../../../services/api';

interface INotificationProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function CreateQueueModal({
  isOpen,
  onRequestClose,
}: INotificationProps): JSX.Element {
  const [updateQueue, setupdateQueue] = useState<IQueue>({} as IQueue);
  const [queues, setQueues] = useState<IQueue[]>([]);
  const [queueID, setQueueID] = useState('');

  const lines = ['LINHA 1', 'LINHA 2', 'LINHA 4', 'LINHA 6', 'B2B'];
  const products = ['FEDERAL', 'ESTADUAL', 'MUNICIPAL'];

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;
      setupdateQueue({ ...updateQueue, id: queueID, [name]: value });
    },
    [queueID, updateQueue],
  );

  const handleEditQueueError = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  const handleCrateQueue = useCallback(async () => {
    try {
      const { data } = await api.post('/schedule/create-new-queue', {
        queue_name: updateQueue.queue_name,
        type: updateQueue.type,
        valor_min: updateQueue.valor_min,
        valor_max: updateQueue.valor_max,
        trf_tj: updateQueue.trf_tj,
        loas: updateQueue.loas,
        active: true,
        reserve_queue: updateQueue.reserve_queue,
        joker_queue: updateQueue.joker_queue,
        line: updateQueue.line,
        product: updateQueue.product,
      });

      const indexUpdatedQueue = queues.findIndex(
        foundQueue => foundQueue.queue_name === '',
      );

      const tempQueue = [...queues];

      tempQueue[indexUpdatedQueue] = data;
      tempQueue.sort((a, b) => Number(a.queue_name) - Number(b.queue_name));
      setQueues(tempQueue);

      setupdateQueue({} as IQueue);

      onRequestClose();
      toast.success('Queue criada com sucesso!');
    } catch (error) {
      handleEditQueueError(error.response.data.message);
    }
  }, [handleEditQueueError, onRequestClose, queues, updateQueue]);

  const closeModal = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={StyleModal}>
      <ModalTitle>Criar nova queue</ModalTitle>
      <Table>
        <Thead>
          <TableRow className="head-row">
            <HeadCell>Nome</HeadCell>
            <HeadCell>Tipo</HeadCell>
            <HeadCell>Valor Mín</HeadCell>
            <HeadCell>Valor Máx</HeadCell>
          </TableRow>
        </Thead>
        <Tbody>
          <TableRow className="body-row">
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="queue_name" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="type" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="valor_min" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="valor_max" />
            </BodyCell>
          </TableRow>
        </Tbody>
      </Table>
      <Table>
        <Thead>
          <TableRow className="head-row">
            <HeadCell>Loas</HeadCell>
            <HeadCell>Reserva</HeadCell>
            <HeadCell>Coringa</HeadCell>
            <HeadCell>TRFs</HeadCell>
          </TableRow>
        </Thead>
        <Tbody>
          <TableRow className="body-row">
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="loas" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="reserve_queue" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="joker_queue" />
            </BodyCell>
            <BodyCell>
              <QueueInput onChange={handleInputChange} name="trf_tj" />
            </BodyCell>
          </TableRow>
        </Tbody>
      </Table>
      <Table>
        <Thead>
          <TableRow className="head-row">
            <HeadCell>Linha</HeadCell>
            <HeadCell>Produto</HeadCell>
          </TableRow>
        </Thead>
        <Tbody>
          <TableRow className="body-row">
            <BodyCell>
              <select
                className="select"
                name="line"
                id="line"
                onChange={handleInputChange}
              >
                <option value="Selecione a linha" disabled>
                  Linha
                </option>
                {lines &&
                  lines.map(line => (
                    <option key={line} id={line} value={line}>
                      {line}
                    </option>
                  ))}
              </select>
            </BodyCell>
          </TableRow>
          <TableRow className="body-row">
            <BodyCell>
              <select
                className="select"
                name="product"
                id="product"
                onChange={handleInputChange}
              >
                <option value="Selecione o produto" disabled>
                  Produto
                </option>
                {products &&
                  products.map(product => (
                    <option key={product} id={product} value={product}>
                      {product}
                    </option>
                  ))}
              </select>
            </BodyCell>
          </TableRow>
        </Tbody>
      </Table>
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CreateQueueButton type="submit" onClick={handleCrateQueue}>
          Criar queue
          <img src={addIcon} alt="Add Icon" />
        </CreateQueueButton>
      </div>
    </Modal>
  );
}
