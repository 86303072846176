import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Container } from '../../../components/Container';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import {
  FileContainer,
  FileInputContainer,
  SendFileContainer,
  SendButtonContainer,
  Separator,
  DivSeparator,
  SpanInput,
} from './styles';
import { Title } from '../../../components/Styles/Title';
import { RegisteredCampaigns } from '../components/RegisteredCampaigns';
import { ICampaigns } from '../dtos/ICampaigns';

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function ShotWpp(): JSX.Element {
  const [file, setFile] = useState<IFileProps>({} as IFileProps);
  const [isActive, setIsActive] = useState(false);
  const [campaigns, setCampaigns] = useState<ICampaigns[]>([]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState<number[]>([1, 2, 3]);

  const handleRequestCampaigns = useCallback(
    async (pageNumber: number) => {
      await axios
        .get(
          `${process.env.REACT_APP_MESSAGES_URL}/whatsapp/campaign/${pageNumber}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
            },
          },
        )
        .then(response => {
          setCampaigns(response.data.campaigns);
          setPage(pageNumber);
          if (
            allPages[allPages.length - 1] === pageNumber &&
            response.data.campaigns.length >= 3
          ) {
            setAllPages([...allPages, pageNumber + 1]);
          }
        });
    },
    [allPages],
  );

  const submitFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const getFile =
      event.target.files === null ? ({} as File) : event.target.files[0];

    const newFile: IFileProps = {
      file: getFile,
      name: getFile.name,
      readableSize: getFile.size.toString(),
    };

    setFile(newFile);
    setIsActive(true);

    event.target.value = null;
  }, []);

  const handleUpload = useCallback(async () => {
    const data = new FormData();

    try {
      data.append('file', file.file);

      await axios
        .post(`${process.env.REACT_APP_MESSAGES_URL}/whatsapp/campaign`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        })
        .then(async () => {
          setFile({} as IFileProps);
          setIsActive(false);
          handleRequestCampaigns(1);
          Swal.fire('Sucesso!', 'Campanha cadastrada com sucesso!', 'success');
        });
    } catch (error) {
      Swal.fire(String(error.response.status), error.response.data, 'error');
    }
  }, [file.file, handleRequestCampaigns]);

  useEffect(() => {
    handleRequestCampaigns(1);
  }, []);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Conexão {'>'} </H1Navigation>
        <H1NavigationNow>Disparo WPP</H1NavigationNow>
      </Navegation>
      <Body>
        <Toaster position="top-right" reverseOrder={false} />
        <Title>Disparo WPP</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <FileContainer>
          <FileInputContainer>
            <SpanInput>Arquivo:</SpanInput>
            <SendFileContainer>
              <div>
                <label htmlFor="file"> Selecione um arquivo</label>
                <input
                  id="file"
                  type="file"
                  name="file"
                  onChange={submitFile}
                />
                {file && file.name ? (
                  <span>{file.name}</span>
                ) : (
                  <span>Nenhum arquivo selecionado</span>
                )}
              </div>
            </SendFileContainer>
            <SendButtonContainer isActive={isActive}>
              <button type="button" onClick={() => handleUpload()}>
                Enviar
              </button>
              <span>Arquivos serão processados automaticamente</span>
            </SendButtonContainer>
          </FileInputContainer>
        </FileContainer>

        <RegisteredCampaigns
          campaigns={campaigns}
          handleRequestCampaigns={handleRequestCampaigns}
          page={page}
          allPages={allPages}
          type="WPP"
        />
      </Body>
    </Container>
  );
}
