import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

import Swal from 'sweetalert2';
import { format } from 'date-fns';
import api from '../../../../../services/api';
import apiCustomerSuccess from '../../../../../services/apiCustomerSuccess';
import { handleCpfAndCnpjMask } from '../../../../../utils/handleCpfAndCnpjMask';

import { HubspotDealDTO } from '../../dtos/HubspotDealDTO';
import { PaymentFormDataDTO } from '../../dtos/PaymentFormDataDTO';
import { CreditorFormDataDTO } from '../../dtos/CreditorFormDataDTO';

import { PaymentForm } from '../PaymentForm';

import {
  FormSessionContainer,
  InputsContainer,
  Label,
  Input,
  Select,
  SubtitleFormSession,
  NewPaymentButton,
  Icon,
} from './styles';
import { PaymentDTO } from '../../dtos/PaymentDTO';
import { formatAmount } from '../../../../../utils/formatAmount';

interface FormattedValues {
  cpf: string;
  nominal_value: number;
  cost_value: number;
  present_value: number;
}

interface Debitor {
  id: string;
  name: string;
}

interface CreditorFormProps {
  paymentFormData: PaymentFormDataDTO[];
  setPaymentFormData: (payment: PaymentFormDataDTO[]) => void;
  creditorFormData: CreditorFormDataDTO;
  setCreditorFormData: (creditor: CreditorFormDataDTO) => void;
  hubspotDeal: HubspotDealDTO;
  setHubspotDeal: (hubspot_deal: HubspotDealDTO) => void;
  setCanSendData: (canSendData: boolean) => void;
  type: string;
  createdPayment: PaymentDTO;
}

export function CreditorForm({
  paymentFormData,
  setPaymentFormData,
  creditorFormData,
  setCreditorFormData,
  hubspotDeal,
  setHubspotDeal,
  setCanSendData,
  type,
  createdPayment,
}: CreditorFormProps): JSX.Element {
  const [quantityBankData, setQuantityBankData] = useState(1);
  const [totalBankData, setTotalBankData] = useState<number[]>([] as number[]);
  const [debitors, setDebitors] = useState<Debitor[]>([] as Debitor[]);

  const [formattedValues, setFormattedValues] = useState<FormattedValues>(
    {} as FormattedValues,
  );

  useEffect(() => {
    apiCustomerSuccess.get('/debitors').then(response => {
      setDebitors(response.data);
    });
  }, []);

  const messageToastAttention = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  useEffect(() => {
    if (createdPayment) {
      if (
        createdPayment.type === 'fundo ii' ||
        createdPayment.type === 'fundo iv'
      ) {
        try {
          apiCustomerSuccess
            .get(`payments/get-hubspot-deal/${createdPayment.hubspot_deal_id}`)
            .then(response => {
              if (response.data.produto_precato !== 'FEDERAL') {
                messageToastAttention(
                  'Produto ESTADUAL/MUNICIPAL vá para o FUNDO III',
                );
              } else {
                setHubspotDeal(response.data);
              }
            });
        } catch {
          setHubspotDeal({} as HubspotDealDTO);
        }
      } else if (createdPayment.type === 'fundo iii') {
        try {
          apiCustomerSuccess
            .get(`payments/get-hubspot-deal/${createdPayment.hubspot_deal_id}`)
            .then(response => {
              if (response.data.produto_precato === 'FEDERAL') {
                messageToastAttention(
                  'Produto FEDERAL  vá para o FUNDO II ou FUNDO IV',
                );
              } else {
                setHubspotDeal(response.data);
              }
            });
        } catch {
          apiCustomerSuccess
            .get(`/payments/find-statecity/${createdPayment.hubspot_deal_id}`)
            .then(response => {
              if (response.data.produto_precato === 'FEDERAL') {
                messageToastAttention(
                  'Produto FEDERAL  vá para o FUNDO II ou FUNDO IV',
                );
              } else {
                setHubspotDeal(response.data);
              }
            });
        }
      }
    }
  }, [createdPayment, messageToastAttention, setHubspotDeal, type]);

  const handleGetHubspotDeal = useCallback(
    async (hubspot_deal_id: string) => {
      try {
        if (hubspot_deal_id && (type === 'fundo ii' || type === 'fundo iv')) {
          await apiCustomerSuccess
            .get(`payments/get-hubspot-deal/${hubspot_deal_id}`)
            .then(response => {
              if (response.data.produto_precato !== 'FEDERAL') {
                messageToastAttention(
                  'Produto ESTADUAL/MUNICIPAL vá para o FUNDO III',
                );
                setCanSendData(false);
              } else {
                setHubspotDeal(response.data);

                if (
                  (response.data.loa === 'LOA 2022' &&
                    !response.data.verificacao_loa_22) ||
                  !response.data.validacao_calculo ||
                  !response.data.validacao_antifraude ||
                  !response.data.verificacao_pre_pagamento
                ) {
                  messageToastAttention(
                    'Dados do negócio não foram preenchidos no Hubspot.',
                  );
                  setCanSendData(false);
                } else {
                  setCanSendData(true);
                }
              }
            });

          setCreditorFormData({
            ...creditorFormData,
            hubspot_deal_id,
          });
        } else if (hubspot_deal_id && type === 'fundo iii') {
          try {
            await apiCustomerSuccess
              .get(`payments/get-hubspot-deal/${hubspot_deal_id}`)
              .then(response => {
                if (response.data.produto_precato === 'FEDERAL') {
                  messageToastAttention('Produto FEDERAL vá para a outra aba');
                  setCanSendData(false);
                } else {
                  setHubspotDeal(response.data);

                  if (!response.data.validacao_calculo) {
                    messageToastAttention(
                      'O campo validação cálculo não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else if (!response.data.validacao_antifraude) {
                    messageToastAttention(
                      'O campo validação antifraude não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else if (!response.data.validacao_broker) {
                    messageToastAttention(
                      'O campo validação broker não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else {
                    setCanSendData(true);
                  }
                }
              });
          } catch {
            await apiCustomerSuccess
              .get(`/payments/find-statecity/${hubspot_deal_id}`)
              .then(response => {
                if (response.data.produto_precato === 'FEDERAL') {
                  messageToastAttention(
                    'Produto FEDERAL  vá para o FUNDO II ou FUNDO IV',
                  );
                  setCanSendData(false);
                } else {
                  setHubspotDeal(response.data);

                  if (!response.data.validacao_calculo) {
                    messageToastAttention(
                      'O campo validação cálculo não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else if (!response.data.validacao_antifraude) {
                    messageToastAttention(
                      'O campo validação antifraude não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else if (!response.data.validacao_broker) {
                    messageToastAttention(
                      'O campo validação broker não foi preenchido.',
                    );
                    setCanSendData(false);
                  } else {
                    setCanSendData(true);
                  }
                }
              });
          }
        } else {
          setHubspotDeal({} as HubspotDealDTO);
        }
      } catch {
        setHubspotDeal({} as HubspotDealDTO);
      }
    },
    [
      creditorFormData,
      messageToastAttention,
      setCanSendData,
      setCreditorFormData,
      setHubspotDeal,
      type,
    ],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      if (name === 'cpf') {
        const formatted = handleCpfAndCnpjMask(value);

        setFormattedValues({
          ...formattedValues,
          [name]: value || hubspotDeal.cpf_credor_a_,
        });

        setCreditorFormData({ ...creditorFormData, [name]: formatted });
      } else if (name === 'nominal_value') {
        setFormattedValues({
          ...formattedValues,
          [name]: Number(value) || Number(hubspotDeal.vlr_liq_pag),
        });

        setCreditorFormData({
          ...creditorFormData,
          [name]: Number(value) || Number(hubspotDeal.vlr_liq_pag),
        });
      } else if (name === 'cost_value') {
        setFormattedValues({
          ...formattedValues,
          [name]: Number(value) || Number(hubspotDeal.valor_de_aquisicao_pago),
        });

        setCreditorFormData({
          ...creditorFormData,
          [name]: Number(value) || Number(hubspotDeal.valor_de_aquisicao_pago),
        });
      } else if (name === 'present_value') {
        setFormattedValues({
          ...formattedValues,
          [name]: Number(value) || Number(hubspotDeal.valor_presente_pago),
        });

        setCreditorFormData({
          ...creditorFormData,
          [name]: Number(value) || Number(hubspotDeal.valor_presente_pago),
        });
      } else {
        setCreditorFormData({ ...creditorFormData, [name]: value });
      }
    },
    [
      creditorFormData,
      formattedValues,
      hubspotDeal.cpf_credor_a_,
      hubspotDeal.valor_de_aquisicao_pago,
      hubspotDeal.valor_presente_pago,
      hubspotDeal.vlr_liq_pag,
      setCreditorFormData,
    ],
  );

  const handleAddPayment = useCallback(() => {
    const quantity = quantityBankData + 1;

    setQuantityBankData(quantity);

    const bankData: number[] = [];

    for (let index = 1; index <= quantity; index++) {
      bankData.push(index);
    }

    setTotalBankData(bankData);
  }, [quantityBankData]);

  return (
    <>
      <FormSessionContainer>
        <InputsContainer>
          <Label className="hubspot_id">ID Hubspot</Label>
          <Input
            name="hubspot_deal_id"
            defaultValue={createdPayment?.hubspot_deal_id}
            onChange={event => handleGetHubspotDeal(event.target.value)}
          />
        </InputsContainer>
      </FormSessionContainer>

      <SubtitleFormSession>Dados do negócio</SubtitleFormSession>
      <FormSessionContainer>
        <InputsContainer>
          <Label className="transaction">Validação de cálculo:</Label>
          <Input
            className="business-data"
            defaultValue={
              hubspotDeal.validacao_calculo
                ? format(new Date(hubspotDeal.validacao_calculo), 'dd/MM/yyyy')
                : ''
            }
            disabled
          />
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Validação antifraude:</Label>
          <Input
            className="business-data"
            defaultValue={hubspotDeal?.validacao_antifraude}
            disabled
          />
        </InputsContainer>

        <InputsContainer>
          <Label className="transaction">Verificação pré pagamento:</Label>
          <Input
            className="business-data"
            defaultValue={hubspotDeal.verificacao_pre_pagamento}
            disabled
          />
        </InputsContainer>

        {(type === 'fundo ii' || type === 'fundo iv') && (
          <InputsContainer>
            <Label className="transaction">Verificação LOA:</Label>
            <Input
              className="business-data"
              defaultValue={hubspotDeal.verificacao_loa_22}
              disabled
            />
          </InputsContainer>
        )}

        <InputsContainer>
          <Label className="transaction">Validação broker:</Label>
          <Input
            className="business-data"
            defaultValue={hubspotDeal.verificacao_pre_pagamento}
            disabled
          />
        </InputsContainer>
      </FormSessionContainer>

      <SubtitleFormSession>Dados do credor</SubtitleFormSession>

      <FormSessionContainer>
        <InputsContainer>
          <Label className="transaction">Nome sacado:</Label>
          <Select
            name="debitor_name"
            defaultValue=""
            onChange={handleInputChange}
          >
            <option disabled value="">
              Selecione uma opção
            </option>
            {debitors &&
              debitors.map(debitor => (
                <option
                  key={debitor.id}
                  value={debitor.name}
                  selected={createdPayment?.debitor.name === debitor.name}
                >
                  {debitor.name}
                </option>
              ))}
          </Select>
        </InputsContainer>
        <InputsContainer>
          <Label className="transaction">Nome credor:</Label>
          <Input
            name="nome_credor"
            defaultValue={hubspotDeal.nome_credor}
            disabled
          />
        </InputsContainer>
        <InputsContainer>
          <Label className="transaction">CPF credor:</Label>
          <Input
            name="cpf"
            maxLength={18}
            defaultValue={
              hubspotDeal.cpf_credor_a_ ||
              (creditorFormData.cpf &&
                handleCpfAndCnpjMask(creditorFormData.cpf))
            }
            onChange={handleInputChange}
          />
        </InputsContainer>
        <InputsContainer>
          <Label className="transaction">Data aquisição:</Label>
          <Input
            type="date"
            name="acquisition_date"
            defaultValue={
              createdPayment &&
              format(new Date(createdPayment.aquisition_date), 'yyyy-MM-dd')
            }
            onChange={handleInputChange}
          />
        </InputsContainer>
        {type === 'fundo iv' ? (
          <>
            <InputsContainer>
              <Label className="transaction">Comissão SEG:</Label>
              <Input
                name="seg_comission"
                defaultValue={
                  hubspotDeal.vlr_liq_pag &&
                  formatAmount(0.05 * Number(hubspotDeal.vlr_liq_pag))
                }
                onChange={handleInputChange}
              />
            </InputsContainer>
            <InputsContainer>
              <Label className="transaction">Comissão TAG:</Label>
              <Input
                name="tag_comission"
                defaultValue={
                  hubspotDeal.vlr_liq_pag &&
                  formatAmount(0.02 * Number(hubspotDeal.vlr_liq_pag))
                }
                onChange={handleInputChange}
              />
            </InputsContainer>
            <InputsContainer>
              <Label className="transaction">Comissão cartório:</Label>
              <Input
                name="office_comission"
                defaultValue={
                  (hubspotDeal.valor_pago_ao_cartorio &&
                    formatAmount(Number(hubspotDeal.valor_pago_ao_cartorio))) ||
                  'R$ 0,00'
                }
                onChange={handleInputChange}
              />
            </InputsContainer>
            <InputsContainer>
              <Label className="transaction">Comissão broker:</Label>
              <Input
                name="broker_comission"
                defaultValue={
                  (hubspotDeal.valor_comissao_broker &&
                    formatAmount(Number(hubspotDeal.valor_comissao_broker))) ||
                  'R$ 0,00'
                }
                onChange={handleInputChange}
              />
            </InputsContainer>
            <InputsContainer>
              <Label className="transaction">% descontos:</Label>
              <Input
                name="cpf"
                maxLength={18}
                defaultValue={hubspotDeal.discount_percent}
                onChange={handleInputChange}
              />
            </InputsContainer>
          </>
        ) : (
          ''
        )}
        <InputsContainer>
          <Label className="transaction">Valor nominal:</Label>
          <Input
            name="nominal_value"
            placeholder="Digite apenas números"
            defaultValue={
              (hubspotDeal.vlr_liq_pag &&
                formatAmount(Number(hubspotDeal.vlr_liq_pag))) ||
              (creditorFormData.nominal_value &&
                formatAmount(creditorFormData.nominal_value))
            }
            onChange={handleInputChange}
          />
        </InputsContainer>
        <InputsContainer>
          <Label className="transaction">Valor custo:</Label>
          <Input
            name="cost_value"
            placeholder="Digite apenas números"
            defaultValue={
              (hubspotDeal.valor_de_aquisicao_pago &&
                formatAmount(Number(hubspotDeal.valor_de_aquisicao_pago))) ||
              (creditorFormData.cost_value &&
                formatAmount(creditorFormData.cost_value))
            }
            onChange={handleInputChange}
          />
        </InputsContainer>
        <InputsContainer>
          <Label className="transaction">Valor presente:</Label>
          <Input
            name="present_value"
            placeholder="Digite apenas números"
            defaultValue={
              (hubspotDeal.valor_presente_pago &&
                formatAmount(Number(hubspotDeal.valor_presente_pago))) ||
              (creditorFormData.present_value &&
                formatAmount(creditorFormData.present_value))
            }
            onChange={handleInputChange}
          />
        </InputsContainer>
      </FormSessionContainer>

      {quantityBankData === 1 ? (
        <PaymentForm
          index={0}
          paymentFormData={paymentFormData}
          setPaymentFormData={setPaymentFormData}
          quantityBankData={quantityBankData}
          setQuantityBankData={setQuantityBankData}
          setTotalBankData={setTotalBankData}
          createdPayment={createdPayment}
        />
      ) : (
        totalBankData &&
        totalBankData.map(quantity => (
          <PaymentForm
            key={quantity}
            index={quantity - 1}
            paymentFormData={paymentFormData}
            setPaymentFormData={setPaymentFormData}
            quantityBankData={quantityBankData}
            setQuantityBankData={setQuantityBankData}
            setTotalBankData={setTotalBankData}
            createdPayment={undefined}
          />
        ))
      )}

      <NewPaymentButton onClick={handleAddPayment}>
        <Icon>
          <AiOutlinePlus />
        </Icon>
        Novo Pagamento
      </NewPaymentButton>
    </>
  );
}
