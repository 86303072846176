import { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import {
  AiOutlineArrowLeft,
  AiOutlineCheck,
  AiOutlineFile,
} from 'react-icons/ai';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';

import { format } from 'date-fns';
import apiCustomerSuccess from '../../../../services/apiCustomerSuccess';

import { Container } from '../../../../components/Container';
import { Title } from '../../../../components/Styles/Title';
import { Body } from '../../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../components/Styles/Navigation';
import { SendFileContainer } from '../../../../components/SendFileContainer';

import { CreditorForm } from './CreditorForm';

import { FilePropsDTO } from '../dtos/FilePropsDTO';
import { HubspotDealDTO } from '../dtos/HubspotDealDTO';
import { PaymentFormDataDTO } from '../dtos/PaymentFormDataDTO';
import { CreditorFormDataDTO } from '../dtos/CreditorFormDataDTO';

import {
  DivSeparator,
  Separator,
  TitleBackButtonContainer,
  BackButtonContainer,
  Icon,
  TitleContainer,
  Main,
  ContainerLoader,
  ButtonsContainer,
  ButtonCancel,
  ButtonSave,
  ButtonSend,
} from './styles';
import { PaymentDTO } from '../dtos/PaymentDTO';

interface ILocation {
  state: {
    type: string;
    payment: PaymentDTO;
  };
}

export function CreatePayment(): JSX.Element {
  const history = useHistory();
  const location: ILocation = useLocation();

  const { type, payment } = location.state;

  const [canSendData, setCanSendData] = useState(true);

  const [hubspotDeal, setHubspotDeal] = useState<HubspotDealDTO>(
    {} as HubspotDealDTO,
  );
  const [creditorFormData, setCreditorFormData] = useState<CreditorFormDataDTO>(
    {} as CreditorFormDataDTO,
  );
  const [paymentFormData, setPaymentFormData] = useState<PaymentFormDataDTO[]>(
    [] as PaymentFormDataDTO[],
  );

  const [file, setFile] = useState<FilePropsDTO>({} as FilePropsDTO);

  const messageToastError = useCallback((message: string) => {
    Swal.fire('Erro!', message, 'error');
  }, []);

  const messageErrorRequest = useCallback((message: string) => {
    toast.error(message);
  }, []);

  const messageSuccessRequest = useCallback((message: string) => {
    toast.success(message);
  }, []);

  const handleCancelCreatePayment = useCallback(() => {
    setHubspotDeal({} as HubspotDealDTO);
    setFile({} as FilePropsDTO);

    history.push('/vortx/payments');
  }, [history]);

  const handleSubmitSaveData = useCallback(async () => {
    try {
      if (
        Object.keys(hubspotDeal).length === 0 ||
        Object.entries(creditorFormData).length < 5 ||
        Object.entries(Object.entries(paymentFormData)[0][1]).length < 5
      ) {
        messageErrorRequest('Preencha todos os campos.');
      } else {
        const dataFile = new FormData();
        dataFile.append('file', file.file);

        creditorFormData.type = type;
        creditorFormData.hubspot_deal_id = hubspotDeal.hubspot_deal_id;

        creditorFormData.cpf =
          creditorFormData.cpf || hubspotDeal.cpf_credor_a_;

        const creditorInfosToString = JSON.stringify(creditorFormData);
        const paymentsToString = JSON.stringify(paymentFormData);

        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        const dataFile2 = new FormData();
        dataFile.append('file', file.file);

        await apiCustomerSuccess.post(
          `/payments?creditor_infos=${creditorInfosToString}&payments=${paymentsToString}`,
          dataFile2,
        );

        messageSuccessRequest('Pagamento criado com sucesso.');
        loader.style.display = 'none';

        setHubspotDeal({} as HubspotDealDTO);
        setCreditorFormData({} as CreditorFormDataDTO);
        setPaymentFormData([] as PaymentFormDataDTO[]);

        history.push(`/vortx/payments`);
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      messageToastError(error.response.data.message);
    }
  }, [
    hubspotDeal,
    creditorFormData,
    paymentFormData,
    messageErrorRequest,
    file.file,
    type,
    messageSuccessRequest,
    history,
    messageToastError,
  ]);

  useEffect(() => {
    if (location.state && payment) {
      const savedPayment = payment;

      const createdPayment: PaymentFormDataDTO = {
        account_type: savedPayment.account_type,
        bank_account: savedPayment.account,
        bank_agency: savedPayment.agency,
        bank_code: savedPayment.bank_code,
        transaction_value: Number(savedPayment.transaction_value),
      };

      paymentFormData[0] = createdPayment;

      setPaymentFormData(paymentFormData);

      const createdCreditor: CreditorFormDataDTO = {
        acquisition_date: format(
          new Date(savedPayment.aquisition_date),
          'yyyy-MM-dd',
        ),
        cost_value: Number(savedPayment.cost_value),
        cpf: savedPayment.cpf_cnpj,
        debitor_name: savedPayment.debitor.name,
        emission_date: savedPayment.emission_date,
        hubspot_deal_id: savedPayment.hubspot_deal_id,
        nominal_value: Number(savedPayment.nominal_value),
        present_value: Number(savedPayment.present_value),
        type,
      };

      setCreditorFormData(createdCreditor);
    }
  }, [location.state, payment, paymentFormData, type]);

  const handleSubmitSendFile = useCallback(async () => {
    try {
      console.log(creditorFormData);
      if (Object.keys(file).length === 0) {
        messageErrorRequest('Selecione um arquivo.');
      } else if (
        Object.entries(creditorFormData).length > 0 &&
        (Object.keys(hubspotDeal).length === 0 ||
          Object.entries(creditorFormData).length < 2 ||
          Object.entries(Object.entries(paymentFormData)[0][1]).length < 5)
      ) {
        messageErrorRequest('Preencha todos os campos.');
      } else {
        if (!payment) {
          if (Object.entries(creditorFormData).length > 0) {
            creditorFormData.cpf =
              creditorFormData.cpf || hubspotDeal.cpf_credor_a_;

            creditorFormData.cost_value =
              creditorFormData.cost_value ||
              Number(hubspotDeal.valor_de_aquisicao_pago);
            creditorFormData.nominal_value =
              creditorFormData.nominal_value || Number(hubspotDeal.vlr_liq_pag);

            creditorFormData.present_value =
              creditorFormData.present_value ||
              Number(hubspotDeal.valor_presente_pago);

            creditorFormData.type = type;
            creditorFormData.hubspot_deal_id = hubspotDeal.hubspot_deal_id;

            const creditorInfosToString = JSON.stringify(creditorFormData);
            const paymentsToString = JSON.stringify(paymentFormData);

            await apiCustomerSuccess.post(
              `/payments?creditor_infos=${creditorInfosToString}&payments=${paymentsToString}`,
            );
          }
        }

        const dataFile = new FormData();
        dataFile.append('file', file.file);

        const loader = document.getElementById('loader');
        loader.style.display = 'flex';

        const paymentsToString = JSON.stringify(paymentFormData);

        const debitorName =
          location.state && payment
            ? payment.debitor.name
            : creditorFormData.debitor_name
            ? creditorFormData.debitor_name
            : '';

        await apiCustomerSuccess.post(
          `/payments/send-file?hubspot_deal_id=${hubspotDeal.hubspot_deal_id}&debitor_name=${debitorName}&payments=${paymentsToString}`,
          dataFile,
        );

        messageSuccessRequest('Arquivo enviado com sucesso.');

        setHubspotDeal({} as HubspotDealDTO);
        setCreditorFormData({} as CreditorFormDataDTO);
        setPaymentFormData([] as PaymentFormDataDTO[]);

        history.push(`/vortx/payments`);
      }
    } catch (error) {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';

      messageToastError(error.response.data.message);
    }
  }, [
    file,
    creditorFormData,
    hubspotDeal,
    paymentFormData,
    messageErrorRequest,
    location.state,
    payment,
    messageSuccessRequest,
    history,
    messageToastError,
    type,
  ]);

  return (
    <Container>
      <Navegation>
        <H1Navigation>Vortx {'>'} </H1Navigation>
        <H1Navigation>Pagamentos {'>'} </H1Navigation>
        <H1NavigationNow>Novo Pagamento</H1NavigationNow>
      </Navegation>

      <Body>
        <Toaster position="top-right" reverseOrder={false} />

        <TitleBackButtonContainer>
          <BackButtonContainer>
            <Link to="/vortx/payments">
              <Icon>
                <AiOutlineArrowLeft />
              </Icon>
              Voltar
            </Link>
          </BackButtonContainer>

          <TitleContainer>
            <Title>Novo pagamento - {type.toUpperCase()}</Title>
            <DivSeparator>
              <Separator />
            </DivSeparator>
          </TitleContainer>
        </TitleBackButtonContainer>

        <Main>
          <CreditorForm
            paymentFormData={paymentFormData}
            setPaymentFormData={setPaymentFormData}
            creditorFormData={creditorFormData}
            setCreditorFormData={setCreditorFormData}
            hubspotDeal={hubspotDeal}
            setHubspotDeal={setHubspotDeal}
            setCanSendData={setCanSendData}
            type={type}
            createdPayment={location.state && payment}
          />

          <SendFileContainer file={file} setFile={setFile} />

          <ContainerLoader id="loader">
            <Loader type="Oval" color="#05233e" height={50} width={50} />
          </ContainerLoader>

          <ButtonsContainer canSendData={canSendData}>
            <ButtonCancel onClick={handleCancelCreatePayment}>
              Cancelar
            </ButtonCancel>

            <ButtonSave
              id="save-button"
              disabled={!canSendData}
              onClick={handleSubmitSaveData}
            >
              <Icon>
                <AiOutlineCheck />
              </Icon>
              Salvar
            </ButtonSave>

            <ButtonSend onClick={handleSubmitSendFile}>
              <Icon>
                <AiOutlineFile />
              </Icon>
              Enviar
            </ButtonSend>
          </ButtonsContainer>
        </Main>
      </Body>
    </Container>
  );
}
