/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useState, ChangeEvent, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { AiOutlineCheck } from 'react-icons/ai';

import { format, addDays, addMinutes, add } from 'date-fns';
import api from '../../../../services/api';

import { Title } from '../../../../components/Styles/Title';
import { ScheduleOptionsButton } from '../../../../components/ScheduleOptionsButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow_right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow_left.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_blue.svg';
import { DayPicker } from '../../../../components/DayPicker';

import {
  ModalSchedule,
  ModalMain,
  Separator,
  DivSeparator,
  TitleContainer,
  Main,
  DateActivityContainer,
  CalendarContainer,
  ActivityContainer,
  DetailsActivityContainer,
  FieldContainer,
  FrequencyActivityContainer,
  InputsRadioContainer,
  InputsContainer,
  Icon,
  ButtonsContainerSchedule,
  ButtonSchedule,
} from './styles';
import { useAuth } from '../../../../hooks/auth';

import { ICloserRestrictedTimeDTO } from '../../dtos/ICloserRestrictedTimeDTO';
import { IActivityDTO } from '../../dtos/IActivityDTO';
import {
  ContainerInput,
  ContainerInputDate,
  DaySelectedModalContainer,
  NextButton,
  PreviousButton,
} from '../../Manager/History/styles';

Modal.setAppElement('#root');

interface ICreateActivityModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  closer_id: string;
  handleCreateActivity: (
    activity: IActivityDTO | ICloserRestrictedTimeDTO,
    typeActivity: string,
  ) => void;
  setSelectedDay: (day: string) => void;
}

interface ICreateActivityFormData {
  name: string;
  description?: string;
  frequency: string;
  time_init: string;
  time_end: string;
}

interface IHubspotDeal {
  hubspot_deal_id: string;
  hubspot_owner_id: string;
  dealname: string;
  amount: string;
  dealstage: string;
  loa: string;
  contato_valido: string;
}

interface IIntervals {
  intervalInit: Date[];
  intervalEnd: Date[];
}

export function ModalCreateActivity({
  isOpen,
  onRequestClose,
  handleCreateActivity,
  setSelectedDay,
  closer_id,
}: ICreateActivityModalProps) {
  const { user } = useAuth();
  const nameActivity = [
    'Agendamento',
    'Almoço',
    'Indisponível',
    'Passagem de bastão',
    'Priorização',
    'Reunião',
  ];
  const [hubspotDeal, setHubspotDeal] = useState<IHubspotDeal>(
    {} as IHubspotDeal,
  );
  const [hoursAvailable, setHoursAvailable] = useState<IIntervals>(
    {} as IIntervals,
  );
  const [formData, setFormData] = useState<ICreateActivityFormData>(
    {} as ICreateActivityFormData,
  );

  const [scheduleDate, setScheduleDate] = useState(new Date());

  const closeModal = useCallback(() => {
    onRequestClose();
    setHubspotDeal({} as IHubspotDeal);
  }, [onRequestClose]);

  const createActivitySuccess = useCallback(() => {
    toast.success('Atividade criada com sucesso');
  }, []);

  const createActivityError = useCallback((message: string) => {
    Swal.fire('Atenção!', `${message}`, 'warning');
  }, []);

  useEffect(() => {
    if (isOpen) {
      const closerId = closer_id || user.id;
      const formattedDate = format(new Date(), 'yyyy-MM-dd');
      api
        .get(
          `schedule/list-closer-possible-hours?closer_id=${closerId}&date=${formattedDate}`,
        )
        .then(response => {
          setHoursAvailable(response.data);
        });
    }
  }, [user, isOpen, closer_id]);

  const formattedDate = useCallback((timestamp: Date, tomorrow?: boolean) => {
    return tomorrow
      ? format(addDays(timestamp, 1), 'yyyy-MM-dd')
      : format(timestamp, 'yyyy-MM-dd');
  }, []);

  useEffect(() => {
    const newDate = formattedDate(scheduleDate);

    const closerId = closer_id || user.id;

    api
      .get(
        `schedule/list-closer-possible-hours?closer_id=${closerId}&date=${newDate}`,
      )
      .then(response => {
        setHoursAvailable(response.data);
      });
  }, [user, formattedDate, scheduleDate, closer_id]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleSetHubspotDeal = useCallback((hubspot_ticket_id: string) => {
    try {
      api
        .get(`schedule/get-hubspot-deal/${hubspot_ticket_id}`)
        .then(response => {
          setHubspotDeal(response.data);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  const formattedTime = useCallback((timestamp: Date) => {
    return `${new Date(timestamp).getHours()}:${String(
      new Date(timestamp).getMinutes(),
    ).padStart(2, '0')}`;
  }, []);

  const formattedAmount = useMemo(() => {
    if (hubspotDeal && hubspotDeal.amount) {
      const amount = Number(hubspotDeal.amount).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });

      return amount;
    }

    return '';
  }, [hubspotDeal]);

  const handleChangeTimeEnd = useMemo(() => {
    const newDate = formattedDate(new Date());

    const timeEnd =
      formData.name === 'Agendamento'
        ? addMinutes(new Date(`${newDate} ${formData.time_init}`), 30)
        : addMinutes(new Date(`${newDate} ${formData.time_init}`), 15);

    return formattedTime(timeEnd);
  }, [formData, formattedDate, formattedTime]);

  const updateAvailableHours = useCallback(() => {
    const indexTimeInit = hoursAvailable.intervalInit.findIndex(
      hour => formattedTime(hour) === formData.time_init,
    );
    const indexTimeEnd = hoursAvailable.intervalEnd.findIndex(
      hour => formattedTime(hour) === formData.time_end,
    );

    const updatedTimeInit = hoursAvailable.intervalInit;
    const updatedTimeEnd = hoursAvailable.intervalEnd;

    updatedTimeInit.splice(indexTimeInit, 1);
    updatedTimeEnd.splice(indexTimeEnd, 1);

    setHoursAvailable({
      ...hoursAvailable,
      intervalInit: updatedTimeInit,
      intervalEnd: updatedTimeEnd,
    });
  }, [hoursAvailable, formData, formattedTime]);

  const handleSetWeekday = useCallback(() => {
    const date = scheduleDate;

    const weekday = date.toLocaleString('default', { weekday: 'long' });

    return weekday;
  }, [scheduleDate]);

  const handleSubmit = useCallback(async () => {
    try {
      const newDate = formattedDate(scheduleDate);

      let response;

      const activitySchema = Yup.object().shape({
        name: Yup.string().required('Nome da atividade obrigatório'),
        time_init: Yup.string().required('Horário inicial obrigatório'),
        time_end: Yup.string().required('Horário inicial obrigatório'),
      });

      await activitySchema.validate(formData, {
        abortEarly: false,
      });

      if (
        formData.name === 'Almoço' ||
        formData.name === 'Indisponível' ||
        formData.name === 'Passagem de bastão' ||
        formData.name === 'Reunião'
      ) {
        response = await api.post('schedule/create-restricted-time', {
          activity: {
            closer_id: closer_id || user.id,
            name: formData.name,
            hubspot_deal_id: hubspotDeal.hubspot_deal_id
              ? hubspotDeal.hubspot_deal_id
              : '',
            dealname: hubspotDeal.dealname ? hubspotDeal.dealname : '',
            amount: hubspotDeal.amount ? hubspotDeal.amount : '',
            description: formData.description ? formData.description : '',
            frequency: formData.frequency ? formData.frequency : 'not_repeat',
            time_init: `${newDate} ${formData.time_init}`,
            time_end: `${newDate} ${formData.time_end}`,
          },
          dealstage: hubspotDeal.dealstage ? hubspotDeal.dealstage : '',
          contato_valido: hubspotDeal.contato_valido
            ? hubspotDeal.contato_valido
            : '',
        });

        handleCreateActivity(response.data, 'Restricted Time');
      } else if (formData.name === 'Priorização') {
        const deal = {
          hubspot_deal_id: hubspotDeal.hubspot_deal_id,
          hubspot_owner_id: hubspotDeal.hubspot_owner_id,
          dealname: hubspotDeal.dealname,
          amount: hubspotDeal.amount,
          dealstage: hubspotDeal.dealstage,
          loa: hubspotDeal.loa,
          contato_valido: hubspotDeal.contato_valido,
        };

        response = await api.post('schedule/create-priority-task', {
          deal,
          closer_id: user.id,
          priorityTime: `${newDate} ${formData.time_init}`,
        });

        handleCreateActivity(response.data, 'Priority');
      } else if (formData.name === 'Agendamento') {
        const deal = {
          hubspot_deal_id: hubspotDeal.hubspot_deal_id,
          dealname: hubspotDeal.dealname,
          amount: hubspotDeal.amount,
          dealstage: hubspotDeal.dealstage,
          loa: hubspotDeal.loa,
          contato_valido: hubspotDeal.contato_valido,
        };

        response = await api.post('schedule/create-scheduling-by-closer', {
          deal,
          closer_id: user.id,
          timestamp: `${newDate} ${formData.time_init}`,
        });

        handleCreateActivity(response.data, 'Scheduling');
      }

      setFormData({
        frequency: '',
        name: '',
        time_init: '',
        time_end: '',
        description: '',
      });

      setHubspotDeal({
        amount: '',
        dealname: '',
        dealstage: '',
        hubspot_deal_id: '',
        hubspot_owner_id: '',
        loa: '',
        contato_valido: '',
      });

      createActivitySuccess();
      updateAvailableHours();
      closeModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        createActivityError('Nome da atividade e horário obrigatórios!');

        return;
      }
      createActivityError(error.response.data.message);
    }
  }, [
    user,
    formData,
    hubspotDeal,
    scheduleDate,
    formattedDate,
    createActivitySuccess,
    createActivityError,
    closeModal,
    handleCreateActivity,
    updateAvailableHours,
  ]);

  const previousDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: -1,
    });
    setScheduleDate(newDate);
  }, [scheduleDate]);

  const nextDate = useCallback(() => {
    const newDate = add(scheduleDate, {
      days: 1,
    });

    setScheduleDate(newDate);
  }, [scheduleDate]);

  const handleDateInputChange = useCallback((day: Date) => {
    setScheduleDate(day);
  }, []);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={ModalSchedule}>
      <ModalMain>
        <TitleContainer>
          <Title>Nova atividade</Title>
          <DivSeparator>
            <Separator />
          </DivSeparator>
        </TitleContainer>

        <Main>
          <DateActivityContainer>
            <CalendarContainer>
              <p className="subtitleModal">Escolha uma data:</p>
              <DaySelectedModalContainer>
                <PreviousButton onClick={previousDate}>
                  <ArrowLeftIcon />
                </PreviousButton>
                <ContainerInput>
                  <ContainerInputDate>
                    <CalendarIcon />
                    <DayPicker
                      handleChange={day => handleDateInputChange(day)}
                      value={scheduleDate}
                    />
                  </ContainerInputDate>
                </ContainerInput>
                <NextButton onClick={nextDate}>
                  <ArrowRightIcon />
                </NextButton>
              </DaySelectedModalContainer>
            </CalendarContainer>
            <br />
            <ActivityContainer>
              <FieldContainer>
                <p className="subtitleModal">Escolha uma atividade:</p>

                <div id="selectActivity">
                  {nameActivity.map(name => (
                    <ScheduleOptionsButton
                      key={name}
                      createActivity
                      selectedValue={formData.name === name}
                      type="button"
                      onClick={() =>
                        setFormData({
                          ...formData,
                          name,
                        })
                      }
                    >
                      {name}
                    </ScheduleOptionsButton>
                  ))}
                </div>
              </FieldContainer>
            </ActivityContainer>
          </DateActivityContainer>

          <DetailsActivityContainer>
            <FieldContainer>
              <p className="subtitleModal">Selecione um horário:</p>

              <div id="selectHour">
                <select
                  name="time_init"
                  id="time_init"
                  defaultValue="Horário inicial"
                  onChange={handleInputChange}
                >
                  <option value="Horário inicial" disabled>
                    Horário inicial
                  </option>
                  {hoursAvailable.intervalInit &&
                    hoursAvailable.intervalInit.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))}
                </select>
                <span id="intervalText">às</span>
                <select
                  name="time_end"
                  id="time_end"
                  defaultValue="Horário final"
                  onChange={handleInputChange}
                >
                  <option value="Horário final" disabled>
                    Horário final
                  </option>
                  {formData.name === 'Agendamento' ||
                  formData.name === 'Priorização' ? (
                    <option value={handleChangeTimeEnd}>
                      {handleChangeTimeEnd}
                    </option>
                  ) : (
                    hoursAvailable.intervalEnd &&
                    hoursAvailable.intervalEnd.map(hourAvailable => (
                      <option
                        key={String(hourAvailable)}
                        value={formattedTime(hourAvailable)}
                      >
                        {formattedTime(hourAvailable)}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </FieldContainer>

            {formData.name === 'Agendamento' ||
            formData.name === 'Priorização' ||
            formData.name === 'Passagem de bastão' ? (
              <FieldContainer>
                <p className="subtitleModal">Dados do negócio:</p>

                <InputsContainer>
                  <input
                    type="text"
                    id="hubspot_deal_id"
                    name="hubspot_deal_id"
                    placeholder="ID do negócio"
                    onChange={e => handleSetHubspotDeal(e.target.value)}
                  />

                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    placeholder="Valor"
                    onChange={handleInputChange}
                    value={formattedAmount || ''}
                    disabled
                  />

                  <input
                    type="text"
                    id="dealname"
                    name="dealname"
                    placeholder="Nome do credor"
                    value={hubspotDeal.dealname || ''}
                    onChange={handleInputChange}
                    disabled
                  />
                </InputsContainer>
              </FieldContainer>
            ) : formData.name === 'Almoço' ||
              formData.name === 'Indisponível' ||
              formData.name === 'Reunião' ? (
              <FieldContainer>
                <p className="subtitleModal">Dados do atividade:</p>

                <InputsContainer>
                  {formData.name === 'Indisponível' ||
                  formData.name === 'Reunião' ? (
                    <input
                      type="text"
                      id="description"
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Descrição da atividade"
                    />
                  ) : (
                    ''
                  )}
                </InputsContainer>

                <FrequencyActivityContainer>
                  <InputsRadioContainer>
                    <input
                      type="radio"
                      id="not_repeat"
                      name="frequency"
                      value="not_repeat"
                      onChange={handleInputChange}
                    />
                    <label htmlFor="not_repeat">Não se repete</label>
                  </InputsRadioContainer>

                  <InputsRadioContainer>
                    <input
                      type="radio"
                      id="weekly"
                      name="frequency"
                      value="weekly"
                      onChange={handleInputChange}
                    />
                    <label htmlFor="weekly">
                      {`Padronizar semanal (Todas as ${handleSetWeekday()})`}
                    </label>
                  </InputsRadioContainer>

                  <InputsRadioContainer>
                    <input
                      type="radio"
                      id="every_day"
                      name="frequency"
                      value="every_day"
                      onChange={handleInputChange}
                    />
                    <label htmlFor="every_day">Padronizar todos os dias</label>
                  </InputsRadioContainer>
                </FrequencyActivityContainer>
              </FieldContainer>
            ) : (
              ''
            )}
          </DetailsActivityContainer>
        </Main>

        <ButtonsContainerSchedule>
          <ButtonSchedule onClick={handleSubmit}>
            <Icon>
              <AiOutlineCheck />
            </Icon>
            Agendar
          </ButtonSchedule>
        </ButtonsContainerSchedule>
      </ModalMain>
    </Modal>
  );
}
