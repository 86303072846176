import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { gql, useSubscription } from '@apollo/client';
import api from '../../../../services/api';

import { ReactComponent as OrderCrescent } from '../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../assets/icons/polygon_down.svg';
import { ReactComponent as AvailableIcon } from '../../../../assets/icons/available_icon.svg';

import {
  Table,
  Thead,
  TableRow,
  Cell,
  ColoredCell,
  ContainerTitle,
  OrderContainerIcon,
  Tbody,
  GuidesContainer,
  Guide,
  TitleGuideContainer,
} from './styles';
import { LineHorizontal } from '../HuntersHistory/styles';

interface IInfo {
  closer_id: string;
  user_id: string;
  closer_name: string;
  closer_avatar: string;
  ticket: number;
  attendance: number;
  schedulings: number;
  priority: number;
  try_times: number;
  talk: number;
  negociations: number;
  blocked: boolean;
  blocked_for_day: Date;
  team: string;
}

interface IInfoTeams {
  line1: IInfo[];
  line2: IInfo[];
  line4: IInfo[];
  line6: IInfo[];
  b2b: IInfo[];
}

interface ICloserHistory {
  scheduleDate: Date;
}

export const ClosersHistory: React.FC<ICloserHistory> = ({
  scheduleDate,
}: ICloserHistory) => {
  const [closersInfo, setClosersInfo] = useState<IInfo[]>([] as IInfo[]);

  const [closersTeams, setClosersTeams] = useState<IInfoTeams>(
    {} as IInfoTeams,
  );
  const [isOrder, setIsOrder] = useState(false);

  const [currentItems, setCurrentItems] = useState<IInfo[]>([] as IInfo[]);
  const [teamsOption, setTeamsOption] = useState<string>('Linha 1');

  const history = useHistory();

  const REFRESH = gql`
    subscription Subscription {
      refreshPanel
    }
  `;

  const refresh = useSubscription(REFRESH);

  const arrayTeams = ['Linha 1', 'Linha 2', 'Linha 4', 'Linha 6', 'B2B'];

  useEffect(() => {
    if (refresh.data) {
      api
        .get(`/schedule/get-closers-info/${format(scheduleDate, 'yyyy-MM-dd')}`)
        .then(response => {
          setClosersInfo(response.data);
        });
    }
  }, [refresh, scheduleDate]);

  useEffect(() => {
    api
      .get(`/schedule/get-closers-info/${format(scheduleDate, 'yyyy-MM-dd')}`)
      .then(response => {
        setClosersInfo(response.data);
      });
  }, [scheduleDate]);

  useEffect(() => {
    const line1 = closersInfo.filter(closer => closer.team === 'LINHA 1');
    const line2 = closersInfo.filter(closer => closer.team === 'LINHA 2');
    const line4 = closersInfo.filter(closer => closer.team === 'LINHA 4');
    const line6 = closersInfo.filter(closer => closer.team === 'LINHA 6');
    const b2b = closersInfo.filter(closer => closer.team === 'B2B');

    setClosersTeams({ line1, line2, line4, line6, b2b });
    setCurrentItems(line1);
  }, [closersInfo]);

  const orderInfoCloserByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = closersInfo.sort((previous: IInfo, next: IInfo) => {
        switch (column) {
          case 'closer_name':
            return previous.closer_name > next.closer_name
              ? 1
              : previous.closer_name < next.closer_name
              ? -1
              : 0;
          case 'ticket':
            return previous.ticket > next.ticket
              ? 1
              : previous.ticket < next.ticket
              ? -1
              : 0;
          case 'attendance':
            return previous.attendance > next.attendance
              ? 1
              : previous.attendance < next.attendance
              ? -1
              : 0;
          case 'schedulings':
            return previous.schedulings > next.schedulings
              ? 1
              : previous.schedulings < next.schedulings
              ? -1
              : 0;
          case 'priority':
            return previous.priority > next.priority
              ? 1
              : previous.priority < next.priority
              ? -1
              : 0;
          case 'try_times':
            return previous.try_times > next.try_times
              ? 1
              : previous.try_times < next.try_times
              ? -1
              : 0;
          case 'talk':
            return previous.talk > next.talk
              ? 1
              : previous.talk < next.talk
              ? -1
              : 0;
          case 'negociations':
            return previous.negociations > next.negociations
              ? 1
              : previous.negociations < next.negociations
              ? -1
              : 0;
          default:
            return null;
        }
      });

      setClosersInfo([...order]);
    },
    [closersInfo, isOrder],
  );

  const orderInfoCloserByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = closersInfo.sort((previous: IInfo, next: IInfo) => {
        switch (column) {
          case 'closer_name':
            return previous.closer_name < next.closer_name
              ? 1
              : previous.closer_name > next.closer_name
              ? -1
              : 0;
          case 'ticket':
            return previous.ticket < next.ticket
              ? 1
              : previous.ticket > next.ticket
              ? -1
              : 0;
          case 'attendance':
            return previous.attendance < next.attendance
              ? 1
              : previous.attendance > next.attendance
              ? -1
              : 0;
          case 'schedulings':
            return previous.schedulings < next.schedulings
              ? 1
              : previous.schedulings > next.schedulings
              ? -1
              : 0;
          case 'priority':
            return previous.priority < next.priority
              ? 1
              : previous.priority > next.priority
              ? -1
              : 0;
          case 'try_times':
            return previous.try_times < next.try_times
              ? 1
              : previous.try_times > next.try_times
              ? -1
              : 0;
          case 'talk':
            return previous.talk < next.talk
              ? 1
              : previous.talk > next.talk
              ? -1
              : 0;
          case 'negociations':
            return previous.negociations < next.negociations
              ? 1
              : previous.negociations > next.negociations
              ? -1
              : 0;
          default:
            return null;
        }
      });

      setClosersInfo([...order]);
    },
    [closersInfo, isOrder],
  );

  const handleRedirectToIndividualCloserHistory = useCallback(
    (closer: IInfo) => {
      history.push({
        pathname: '/negociations/schedule/manager/closer-history/individual',
        state: {
          user_id: closer.user_id,
          closer_id: closer.closer_id,
          blocked: closer.blocked,
          blocked_for_day: closer.blocked_for_day,
          type: 'Manager',
        },
      });
    },
    [history],
  );

  const formatTicket = useCallback((ticket: string) => {
    const formattedTicket =
      ticket === '0'
        ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(0))
        : new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Number(ticket));
    return formattedTicket;
  }, []);

  const handleSelectTeam = useCallback(
    (teamName: string) => {
      setTeamsOption(teamName);
      if (teamName === 'Linha 1') {
        setCurrentItems(closersTeams.line1);
      } else if (teamName === 'Linha 2') {
        setCurrentItems(closersTeams.line2);
      } else if (teamName === 'Linha 4') {
        setCurrentItems(closersTeams.line4);
      } else if (teamName === 'Linha 6') {
        setCurrentItems(closersTeams.line6);
      } else if (teamName === 'B2B') {
        setCurrentItems(closersTeams.b2b);
      }
    },

    [closersTeams],
  );

  return (
    <>
      <GuidesContainer>
        {arrayTeams &&
          arrayTeams.map(team => (
            <Guide
              key={team}
              isSelected={teamsOption === team}
              onClick={() => handleSelectTeam(team)}
            >
              <TitleGuideContainer>
                <span>{team}</span>
                <span />
              </TitleGuideContainer>

              <LineHorizontal />
            </Guide>
          ))}
      </GuidesContainer>
      <Table>
        <Thead>
          <TableRow>
            <Cell>
              <AvailableIcon />
            </Cell>
            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('closer_name')
                  : orderInfoCloserByDesc('closer_name')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Closer</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('ticket')
                  : orderInfoCloserByDesc('ticket')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Ticket médio</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('attendance')
                  : orderInfoCloserByDesc('attendance')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Atendimentos</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('schedulings')
                  : orderInfoCloserByDesc('schedulings')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Agendamentos</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('priority')
                  : orderInfoCloserByDesc('priority')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Priorização diária</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('try_times')
                  : orderInfoCloserByDesc('try_times')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Tentou</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('talk')
                  : orderInfoCloserByDesc('talk')
              }
              style={{ borderRight: '1px solid #9d9d9d' }}
            >
              <ContainerTitle>Falado</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>

            <Cell
              onClick={() =>
                isOrder
                  ? orderInfoCloserByAsc('negociations')
                  : orderInfoCloserByDesc('negociations')
              }
            >
              <ContainerTitle>Negociação</ContainerTitle>
              <OrderContainerIcon>
                <OrderCrescent />
                <OrderDecrescent />
              </OrderContainerIcon>
            </Cell>
          </TableRow>
        </Thead>
        <Tbody>
          {currentItems &&
            currentItems.map(closer => (
              <TableRow
                key={closer.closer_id}
                closerAvailable={
                  closer.blocked === false &&
                  (!closer.blocked_for_day ||
                    new Date(closer.blocked_for_day).getDate() !==
                      new Date(scheduleDate).getDate())
                }
                onClick={() => handleRedirectToIndividualCloserHistory(closer)}
              >
                <Cell
                  closerAvailable={
                    closer.blocked === false &&
                    (!closer.blocked_for_day ||
                      new Date(closer.blocked_for_day).getDate() !==
                        new Date(scheduleDate).getDate())
                  }
                >
                  <img src={closer.closer_avatar} alt="Foto closer" />
                </Cell>
                <Cell>
                  <span>{closer.closer_name}</span>
                </Cell>
                <Cell>{formatTicket(String(closer.ticket))}</Cell>
                <Cell>{closer.attendance}</Cell>

                <ColoredCell quantidade={closer.schedulings}>
                  <strong>{closer.schedulings}</strong>
                </ColoredCell>

                <Cell>{closer.priority}</Cell>
                <Cell>{closer.try_times}</Cell>
                <Cell>{closer.talk}</Cell>

                <ColoredCell quantidade={closer.negociations}>
                  <strong>{closer.negociations}</strong>
                </ColoredCell>
              </TableRow>
            ))}
        </Tbody>
      </Table>
    </>
  );
};
